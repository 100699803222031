<template>
  <div>
    <el-table :data="tableData.data" v-loading="tableLoading" height="50vh">
      <el-table-column label="Number" prop="number"></el-table-column>
      <el-table-column label="Message" prop="message"></el-table-column>
      <el-table-column label="Sender ID" prop="sender_id"></el-table-column>
      <el-table-column prop="status" label="Status" width="150">
        <template slot-scope="scope" class="contains-status">
          <p
            v-if="scope.row.status.toUpperCase() == 'DELIVERED'"
            style="color: #36d962; margin: 0 auto"
          >
            DELIVERED
          </p>
          <p
            v-else-if="scope.row.status.toUpperCase() == 'NOT_DELIVERED'"
            style="color: #000; margin: 0 auto"
          >
            NOT DELIVERED
          </p>
          <p
            v-else-if="scope.row.status.toUpperCase() == 'SUBMITTED'"
            style="color: #30a1f0; margin: 0 auto"
          >
            SUBMITTED
          </p>
          <p
            v-else-if="scope.row.status.toUpperCase() == 'FAILED'"
            style="color: #f0c630; margin: 0 auto"
          >
            ACCEPTED
          </p>
          <p
            v-else-if="scope.row.status.toUpperCase() == 'EXPIRED'"
            style="color: #f7921c; margin: 0 auto"
          >
            EXPIRED
          </p>
          <p
            v-else-if="scope.row.status.toUpperCase() == 'REJECTED'"
            style="color: #bc76ba; margin: 0 auto"
          >
            REJECTED
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Date & Time" prop="date_time"></el-table-column>
    </el-table>
    <div class="col-md-12" style="text-align: center; margin-top: 2em">
      <el-pagination
        v-if="tableData.data"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="tableData.per_page || 0"
        :total="tableData.total || 0"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      tableLoading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.$emit("updatePage", val);
      },
    },
    computed: {
      tableData() {
        return this.$store.state.admin.reseller.resellerReports;
      },
    },
    mounted() {
      this.$store.dispatch("admin/reseller/getResellerDomainNames");
      this.$store.dispatch("admin/reseller/clearResellerReports");
    },
  };
</script>

<style scoped></style>
